#marquee-txt {
    position:relative;
    width:100vw;
    height: 100vh;
    display: flex;
    margin-top: 5rem;
}

#marquee-txt span{
    position: absolute;
    padding-right: 1em;
}


.SloganContainer{
    position: relative;
    display: flex;
    /* width: 300vw; */
    height: 15vh;
    transform: translateY(100%);
    opacity:0;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
}

.Slogan1{
    /* position: absolute; */
    /* width: 150vw; */
    height: auto;
}
